@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  text-align: center;
  .paper {
    height: 400px;
    min-height: 400px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;

    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .content {
      height: 60%;
      width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-weight: 900;
        font-size: 26px;
        line-height: 30px;
      }
      .subtitle {
        font-weight: 500;
        font-size: 14px;
      }
      .btns {
        width: 100%;
        font-weight: 500;
        height: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        line-height: 18px;
        .btnConfirm {
          width: 170px;
          height: 46px;
          background-color: $color-stop;
        }
        .btnNotConfirm {
          width: 170px;
          height: 46px;
          background-color: $color-gray2;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .content {
        width: 85% !important;
      }
      .btns {
        justify-content: space-evenly !important;
        .btnConfirm {
          width: 122px !important;
          height: 46px;
        }
        .btnNotConfirm {
          width: 122px !important;
          height: 46px;
        }
      }
    }
  }
}
