@import 'styles/constants';
@import 'styles/mixins/breakpoints';

:root .children {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px;
  padding-bottom: 10px;

  > * {
    width: calc(50% - 30px);
    margin: 10px 15px !important;
    i {
      color: $color-gray;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .child {
    padding-left: 11px;
    &.selected {
      border: 1px solid $color-main-green;
      padding-left: 10px;
      padding-right: 16px;
      .check i {
        color: $color-main-green;
      }

      .extra {
        right: -1px;
        top: -1px;
      }
    }

    .editBtn {
      margin-right: 5px;
      svg {
        color: $color-main;
      }
    }

    .extra {
      position: absolute;
      background: $color-stop;
      box-shadow: 0 0 10px rgba(235, 87, 87, 0.4);
      color: #fff;

      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: bold;
      height: 16px;
      font-size: 8px;
      line-height: 9px;
      text-transform: uppercase;
      top: 0;
      right: 0;
      padding: 0 12px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      min-width: 85px;

      &.filled {
        background: $color-main-green;
        box-shadow: 0 0 10px rgba(58, 149, 69, 0.4);
      }
    }
  }

  .text {
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: left;
    margin: 0 8px 0 17px;
    min-width: 1px;

    .fio {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .subtitle {
      font-size: 13px;
      line-height: 15px;
      color: #8a8a8f;
      font-weight: normal;
      margin-bottom: 8px;
    }
  }
}
@include media-breakpoint-down(sm) {
  :root .children {
    display: block;
    > * {
      width: calc(100% - 30px);
    }
  }
}
