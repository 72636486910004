@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$itemMargin: 15px;

.root {
  .search {
    margin-bottom: 20px;
    margin-top: -$itemMargin / 2;
  }

  @include media-breakpoint-up(md) {
    .items {
      margin: -$itemMargin;
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      position: relative;
      padding: 20px 16px 16px;
      margin: $itemMargin;
      width: calc(50% - #{2 * $itemMargin});
      min-height: 350px;
    }
    .titleHeigth {
      height: 45px;
    }
  }

  @include media-breakpoint-down(md) {
    .items {
      margin: $itemMargin;
    }
    .item {
      position: relative;
      padding: 20px 16px 16px;
      margin: $itemMargin;
      width: calc(100% - #{2 * $itemMargin});
    }
  }
  .item {
    .label {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: $color-main;
      box-shadow: 0 0 10px rgba(156, 129, 240, 0.4);
      font-size: 12px;
      line-height: 16px;
      min-width: 100px;
      text-align: center;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 2px 12px;
    }

    .sectionHeader {
      border-bottom: 1px solid #e0e0e0;
      padding: 16px 0;
      height: 35%;
      min-height: 35%;

      .row:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .sectionHeaderHeight {
      height: 42%;
      min-height: 42%;
    }
    .sectionHeightSchool {
      height: 30%;
      min-height: 30%;
    }
    .section {
      border-bottom: 1px solid #e0e0e0;
      padding: 16px 0;

      .row:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .docSection {
      padding-top: 16px;
      font-size: 16px;
      line-height: 19px;
      color: $color-text;
    }

    .row {
      font-size: 16px;
      line-height: 19px;
      color: $color-text;

      > *:first-child {
        color: $color-gray2;
      }
    }
    .display {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $color-main;
      margin-bottom: 8px;
    }

    .date {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $color-gray2;
      height: 24px;
      margin-top: 10px;

      background: #f8f8f8;
      border: 1px solid $color-gray2;
      border-radius: 31px;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      i {
        margin-right: 7px;
      }
    }

    @include media-breakpoint-up(xs) {
      .date {
        padding: 0 14px;
      }
    }

    .docs {
      margin-top: 18px;
      padding-bottom: 5px;
      display: flex;
      overflow-x: auto;
      scrollbar-color: #f5f5f5;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background-color: #f5f5f5;
        border-radius: 1.5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b0b4b8;
        border-radius: 1.5px;
      }

      .doc {
        background: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        font-size: 14px;
        line-height: 17px;
        color: $color-gray2;
        padding: 8px 4px;
        min-width: 80px;
        min-height: 94px;
        position: relative;

        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .signed {
            font-size: 12px;
            color: #3a9545;
          }
          .renouncement {
            font-size: 12px;
            color: #eb5757;
          }
          .needSign {
            font-size: 12px;
            color: #69488d;
          }
        }
        .animationFirst {
          animation: HiddenText 3.2s;
          animation-iteration-count: infinite;
          position: absolute;
          padding: 8px 4px;
        }
        .actContent {
          width: 100%;
          height: 100%;
          animation: ShowText 3.2s;
          animation-iteration-count: infinite;
          position: absolute;
          padding: 14px 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 500;
          border: 1px solid #69488d;
          border-radius: 8px;
          .needSign {
            font-size: 12px;
            color: #69488d;
            line-height: 1.16;
            margin-top: 8px;
          }
        }
        @keyframes HiddenText {
          0% {
            opacity: 1;
          }
          30% {
            opacity: 1;
          }
          40% {
            opacity: 0;
          }
          80% {
            opacity: 0;
          }
          90% {
            opacity: 1;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes ShowText {
          0% {
            opacity: 0;
          }
          30% {
            opacity: 0;
          }
          40% {
            opacity: 1;
          }
          80% {
            opacity: 1;
          }
          90% {
            opacity: 0;
          }
          100% {
            opacity: 0;
          }
        }
      }

      .doc + .doc {
        margin-left: 16px;
      }
    }
  }
}

.imgWrapper {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.emptyDataContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 132px);
  max-height: 700px;
  flex-direction: column;

  .emptyData {
    font-style: normal;
    font-weight: normal;
    position: relative;
    top: 60px;
    font-size: 24px;
    line-height: 140%;
    color: $color-gray2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .iconForEmpty {
    width: 342px;
    height: 255px;
    text-align: center;
  }

  .textForEmpty {
    margin-top: 10px;
    text-align: center;
    width: 60%;
  }
  @include media-breakpoint-down(sm) {
    .textForEmpty {
      width: 70%;
    }
  }
}

.paddingElem {
  margin-bottom: 10px;
}
