@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.panel {
  padding: 50px;

  .footer {
    text-align: right;
    margin-top: 50px;
    button {
      min-width: 215px;
    }
  }
}

.iframe {
  width: 100%;
  height: 640px;
  min-height: 640px;
}

@include media-breakpoint-down(sm) {
  .panel {
    padding: 10px;
    .footer {
      text-align: center;
    }
  }
}
