@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.subtitle {
  font-size: 18px;
  line-height: 120%;
  color: $color-text;
  margin: 12px 0 12px;
}
.container {
  width: 100%;
  height: 242px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0 50px;
}
