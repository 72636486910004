.root {
  z-index: 99999 !important;

  display: flex;
  align-items: center;
  justify-content: center;
  //pointer-events: none;
  > *:focus {
    outline: none;
  }
}
