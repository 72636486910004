@import 'styles/mixins/breakpoints';

:root .root {
  @include media-breakpoint-down(sm) {
    label {
      width: 120%;
    }
  }
  &:not(.noHelper) {
    padding-bottom: 17px;

    .helper {
      position: absolute;
      bottom: -3px;
    }
  }
}
@include media-breakpoint-down(xs) {
  .root {
    white-space: nowrap;
    overflow: hidden;

    text-overflow: ellipsis;
  }
}
