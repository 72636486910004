.root {
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  margin-left: -17px;
  opacity: 0;
}
