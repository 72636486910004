@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  .title {
    font-weight: 800;
    font-size: 26px;
    line-height: 31px;
    color: $color-text;
    //margin-bottom: 41px;
  }

  .content {
    //margin-bottom: 41px;
    padding: 41px 0;
    > *:first-child {
      margin-bottom: 32px;
    }
  }
  @include media-breakpoint-down(xs) {
    .content {
      padding-left: 0px;
      padding-right: 0px;
    }
    .btnMobile {
      width: 218px;
      height: 46px;
      font-weight: 500;
      font-size: 15px;
    }
  }
  @include media-breakpoint-between(xs, sm) {
    .title {
      font-weight: 900;
      font-size: 24px;
    }
    .btnMobile {
      width: 218px;
      height: 46px;
      font-weight: 500;
      font-size: 15px;
    }
    .content {
      padding-left: 35px;
      padding-right: 34px;
    }
  }
}

.link {
  color: $color-main;
  cursor: pointer;
}
