.container {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 999;
  height: 100px;
  width: 100%;
  background-color: #69488d;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: white;
  @media (max-width: 833px) {
    padding: 24px 40px;
    flex-direction: column;
    height: auto;
  }
  .text {
    width: 75%;
    @media (max-width: 833px) {
      width: 100%;
      text-align: center;
    }
  }
  .button {
    width: 215px;
    height: 46px;
    @media (max-width: 833px) {
      margin-top: 50px;
    }
    .buttonText {
      width: 100%;
      background-color: white;
      color: #69488d;
    }
  }
}
