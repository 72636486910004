@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  //width: 730px;

  .content {
    max-width: 350px;
    font-size: 14px;
    line-height: 16px;
    color: $color-gray;
    text-align: center;

    .header {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: $color-text;
      margin-bottom: 32px;
    }

    .email {
      font-size: 24px;
      line-height: 120%;
      color: $color-main;
      margin-bottom: 32px;
    }

    button {
      width: 100%;
    }

    .repeatBtn {
      font-size: 18px;
      color: $color-text;
    }

    .send {
      height: 48px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        margin: 0;
      }
    }
  }
}
@include media-breakpoint-up(sm) {
  .root {
    padding: 50px 190px;
    border-radius: 10px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
@include media-breakpoint-down(sm) {
  .root {
    padding: 50px 10px;
    border-radius: 10px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .content {
      button {
        width: 218px;
        height: 46px;
        display: block;
        margin: auto;
      }
    }
  }
}
