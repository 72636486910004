@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.374px;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    width: 50%;
  }

  .subtitle {
    font-size: 14px;
    color: $color-gray2;
    margin-top: 25px;
  }

  .mainTitle {
    font-size: 32px;
    font-weight: 700;
  }
  .editCBtn {
    color: $color-main;
    margin-bottom: -12px;
    margin-top: -12px;
    margin-right: 250px;
  }

  @include media-breakpoint-down(sm) {
    .editCBtn {
      display: none;
    }
    .title {
      width: 100%;
    }
    .subtitle {
      margin-top: 5px;
    }
  }

  .editPBtn {
    min-width: 215px;
    height: 46px;
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .status {
    font-size: 18px;
    line-height: 120%;
    flex: 1;
    //display: flex;
    //align-items: center;
    text-align: right;
    letter-spacing: 0.374px;

    color: $color-gray2;
  }
}
