@import 'styles/constants';
.root {
  padding: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1f2126;

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: $color-gray2;
    margin-bottom: 8px;
  }
}
