@import 'styles/constants';

$radius: 10px;
$noPhoto-size: 54px;
.rootBaseItem {
  height: 78px;
}

.text {
  flex: 1;
  margin: 0 16px;
  min-width: 1px;
  white-space: nowrap;

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .subtitle {
    font-size: 13px;
    line-height: 15px;
    color: #8a8a8f;
    margin-bottom: 8px;
  }
}

.addIco {
  color: $color-gray;
}

//.noPhoto {
//  width: $noPhoto-size;
//  height: $noPhoto-size;
//  background-color: #c8c7cc;
//  color: #fff;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  border-radius: $noPhoto-size / 2;
//}

:root .root {
  padding: 17px;
  list-style-type: none;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 78px;
  display: flex;
  align-items: center;
  color: $color-gray2;
  cursor: pointer;
  //border: 1px solid transparent;

  &.active {
    border: 1px solid $color-main;
    box-shadow: 0 0 10px rgba(156, 129, 240, 0.5);
    padding: 16px;
  }

  .ico {
  }
  .text {
    flex: 1;
    margin: 0 16px;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #1f2126;
    }
    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $color-gray2;
      margin-bottom: 8px;
    }
  }
}
