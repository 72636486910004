@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  overflow-y: auto;
  scrollbar-color: #f5f5f5;
  scrollbar-width: thin;
  position: relative;
  width: 100%;
  padding-bottom: 80px;

  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #f5f5f5;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b4b8;
    border-radius: 3px;
  }

  .container {
    background-color: white;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    .panelContainer {
      padding: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      .content {
        width: 100%;
        margin-left: 30px;
        color: #1f1f1f;
        font-size: 14px;

        .title {
          font-size: 16px;
          color: $color-main;
          font-weight: 500;
          padding-bottom: 15px;
          border-bottom: 1px solid #c8c7cc;
        }
        .refsContainer {
          margin-top: 18px;
          .ref {
            display: flex;
            cursor: pointer;
            margin-bottom: 5px;
            text-decoration: underline;
            font-size: 14px;
            div {
              margin-left: 10px;
            }
            a {
              margin-left: 10px;
              color: #1f1f1f;
              text-decoration: none;
            }
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .imgContainer {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      max-width: 300px;
      svg {
        cursor: pointer;
      }
    }
  }
  .appDownloadForMobile {
    display: none;
  }
  .appDownload {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 100px;

    .text {
      font-size: 16px;
      font-weight: 400;
      margin-right: 20px;

      a {
        text-decoration: none;
        color: #69488d;
      }
    }
  }
}
@media (max-width: 1024px) {
  .root {
    padding: 0 16px;
    padding-bottom: 80px;
  }
}

@media (max-width: 834px) {
  .root {
    align-items: center;
    padding: 0 167px;
    padding-bottom: 100px;
    .wrapper {
      display: block;
      .container {
        &:not(last-child) {
          margin-bottom: 30px;
        }
      }
    }
    .appDownload {
      flex-direction: column-reverse;
      min-height: 160px;
      .text {
        margin-right: 0px;
        margin-top: 20px;
        a {
          text-decoration: none;
          color: #69488d;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .root {
    padding: 0 130px;
    padding-bottom: 100px;
  }
}
@media (max-width: 640px) {
  .root {
    padding: 0 47px;
    padding-bottom: 100px;
    .container {
      .panelContainer {
        display: block;
        .leftSide {
          text-align: center;
        }
        .content {
          margin-left: 0px;
          margin-top: 30px;
        }
      }
    }
    .appDownload {
      display: none;
    }
    .appDownloadForMobile {
      display: block;
      text-align: center;
      .title {
        font-size: 20px;
        font-weight: 500;
      }
      .btns {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        button {
          border-radius: 5px;
          background-color: #69488d;
          &:last-child {
            margin-top: 20px;
          }
          a {
            width: 100%;
            text-decoration: none;
            .btn {
              color: white;
              text-decoration: none;
              font-weight: 300;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 388px) {
  .root {
    padding: 0px 20px;
    padding-bottom: 80px;
  }
}
@media (max-width: 330px) {
  .root {
    .container {
      .panelContainer {
        padding: 25px;
      }
    }
  }
}
