@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  height: 100vh;
  background-color: $color-background;
}

.content {
  max-width: $layout-content-width;
  margin: 0 auto;
  display: flex;
  height: calc(100vh - #{$layout-header-height});
  padding-top: 32px;
}
