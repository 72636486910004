@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$avatar-size: 72px;
.root {
  .avatarWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 33px;
    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $color-text;
    }
  }

  .avatar {
    width: $avatar-size;
    height: $avatar-size;
    background-color: $color-gray;
    color: #fff;
    margin-right: 16px;

    &:hover {
      background-color: $color-gray;
    }
  }

  .header {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-gray;
    min-height: 53px;
  }

  .inputMobile {
    display: none !important;
  }
  .inputDesctop {
    display: flex !important;
  }

  @include media-breakpoint-down(sm) {
    .inputMobile {
      display: block !important;
    }
    .inputDesctop {
      display: none !important;
    }
    .header {
      border-bottom: none;
    }
  }
}
