@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  .title {
    font-weight: 800;
    font-size: 26px;
    line-height: 31px;
    color: $color-text;
    margin-bottom: 39px;
  }

  .phone {
    font-size: 16px;
    line-height: 19px;
  }

  .descr {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin: 32px 0;
  }
  @include media-breakpoint-down(sm) {
    .title {
      font-weight: 900;
      font-size: 24px;
    }
    .btnMobile {
      width: 218px !important;
      height: 46px !important;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
