@import 'styles/constants';
.root {
  font-size: 24px;
  line-height: 140%;
  color: $color-gray2;
  max-width: 540px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  max-height: 700px;
  margin: 0 auto;
  height: 100%;
}
