@import 'styles/constants';
.root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: $color-text;
  cursor: pointer;
  text-align: left;

  i {
    margin-right: 20px;
    color: $color-gray;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.checked {
    i {
      color: $color-main-green;
    }
  }
}
