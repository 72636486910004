@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$contentWidth: 350px;
$contentWidthSm: 290px;

.root {
  .paper {
    //padding: 40px 190px;
    position: relative;
    height: 442px;
    min-height: 442px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .content {
    margin: 0 auto;
    width: $contentWidth;
  }

  .title {
    font-weight: 900;
    font-size: 28px;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 35px;
    text-align: center;
  }

  .btnContinue {
    margin-top: 10px;
    width: $contentWidth;
    height: 64px;
  }

  .content {
    margin: 0 auto;
    width: $contentWidth;
  }

  @include media-breakpoint-down(sm) {
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
    }

    .btnContinue {
      width: 218px;
      height: 46px;
      display: block;
      margin: auto;
    }

    .content {
      width: $contentWidthSm;
    }
  }
}
