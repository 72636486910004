@import 'styles/constants';

.root {
  display: flex;
  flex-direction: column;
}

.notActive {
  opacity: 0.5;
}

.programmsParallel {
  width: 100%;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $color-main;
}
.programmsName {
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: $color-gray2;
}
