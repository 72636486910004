@import 'styles/mixins/breakpoints';
.root {
  //border: 1px solid red;
  flex: 1;
  padding: 0 16px;
  min-width: 1px;

  .simpleBar {
    //min-height: 500px;
    margin: -15px -15px 0;
    padding: 15px 15px 0;

    :global(.simplebar-content) {
      padding: 15px 15px 0 !important;
    }
    :global(.simplebar-wrapper) {
      margin: -15px -15px 0 !important;
    }
  }

  .wrapper {
    padding-bottom: 10px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    width: 100%;
    &.rootPath {
      display: none;
    }
  }
}
