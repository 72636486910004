@import 'styles/constants';

$radius: 10px;
:root .root {
  padding: 17px;
  list-style-type: none;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 78px;
  display: flex;
  align-items: center;
  color: $color-gray2;
  cursor: pointer;
  //border: 1px solid transparent;

  &.active {
    border: 1px solid $color-main;
    box-shadow: 0 0 10px rgba(156, 129, 240, 0.5);
    padding: 16px;

    .extra {
      right: -1px;
      top: -1px;
    }
  }

  .extra {
    position: absolute;
    background: $color-stop;
    box-shadow: 0 0 10px rgba(235, 87, 87, 0.4);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    height: 16px;
    font-size: 8px;
    line-height: 9px;
    text-transform: uppercase;
    top: 0;
    right: 0;
    padding: 0 12px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width: 85px;

    &.filled {
      background: $color-main-green;
      box-shadow: 0 0 10px rgba(58, 149, 69, 0.4);
    }
  }
}
