@import 'styles/constants';
:root .root {
  height: 48px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  font-size: 14px;
  line-height: 120%;
  color: $color-text;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;

  i {
    color: $color-gray;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.checked {
    border-color: $color-main-green;

    i {
      color: $color-main-green;
    }
  }
}
