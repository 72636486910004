@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  text-align: center;
  //padding: 40px 190px;
  //height: 442px;

  .paper {
    //padding: 40px 190px;
    position: relative;
    height: 442px;
    min-height: 442px;
    align-items: center;
    display: flex;
    flex-direction: row;

    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .content {
    margin: 0 auto;
    max-width: 350px;
    > * {
      width: 100%;
    }

    .title {
      font-weight: 900;
      font-size: 26px;
      line-height: 30px;
      color: $color-text;
      margin-bottom: 21px;
    }

    .text,
    .error {
      font-size: 14px;
      line-height: 16px;
      color: $color-gray;

      span {
        color: $color-main;
      }
    }

    .code {
      height: 73px;
    }

    .timer {
      font-size: 18px;
      line-height: 21px;
      color: $color-text;
      margin-bottom: 32px;
      margin-top: 4px;
    }

    .pinWrapper {
      position: relative;

      .error {
        position: absolute;
        color: $color-stop;
        bottom: -26px;
        width: 100%;
      }

      :global(.pincode-input-container) {
        margin-top: 40px;
        margin-bottom: 40px;

        input {
          font-size: 36px;
          line-height: 42px;
          border: none !important;
          border-bottom: 1px solid #1f1f1f !important;
          margin: 0 4px !important;
        }
      }
      @include media-breakpoint-down(sm) {
        :global(.pincode-input-container) {
          margin-top: 40px;
          margin-bottom: 40px;

          input {
            width: 30px !important;
          }
        }
      }
    }
    button {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      button {
        width: 218px;
        height: 46px;
        margin: 0 auto;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
