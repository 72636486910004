@import 'styles/constants';
.root {
  display: flex;
  align-items: center;
  padding: 16px 0;
  font-size: 18px;
  line-height: 120%;

  > * {
    flex: 50%;
    flex-shrink: 0;
    min-width: 1px;
  }

  .label {
    color: $color-gray2;
  }

  .value {
    padding-right: 29px;
    word-break: break-word;
  }

  &.editMode {
    .value {
      color: $color-gray;
    }
  }

  .errorText {
    font-size: 12px;
    line-height: 14px;
    color: $color-stop;
    margin-top: 2px;
  }
}
