@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$itemMarginX: 15px;
$itemMarginY: 10px;
.items {
  display: flex;
  flex-wrap: wrap;
  margin: -$itemMarginY (-$itemMarginX);

  > * {
    width: calc(50% - #{$itemMarginX * 2});
  }

  > * {
    margin: $itemMarginY $itemMarginX;
  }

  .item {
    position: relative;
    .extra {
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: bold;
      height: 16px;
      font-size: 8px;
      line-height: 9px;
      text-transform: uppercase;
      color: #fff;
      background-color: $color-stop;
      position: absolute;
      top: 0;
      right: 0;
      min-width: 85px;
      padding: 0 12px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 0 10px rgba(235, 87, 87, 0.4);

      &.filled {
        background-color: $color-main-green;
        box-shadow: 0 0 10px rgba(58, 149, 69, 0.4);
      }
    }

    svg {
      height: 24px;
      width: 24px;
    }
    .text {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      flex: 1;
      max-width: 75%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 18px;
      @media (max-width: 1024px) {
        max-width: 100%;
      }
      .title {
        font-size: 16px;
        line-height: 120%;
        color: #1f2126;
        margin-top: 8px;
      }
    }
    @include media-breakpoint-down(xs) {
      .text {
        width: 60%;
        word-wrap: break-word;
      }
    }
    .editBtn {
      color: $color-main;
      margin: -12px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .items {
    display: block;
    margin: -$itemMarginY (-$itemMarginX);

    > * {
      width: calc(100% - #{$itemMarginX * 2});
    }

    > * {
      margin: $itemMarginY $itemMarginX;
    }
  }
}
