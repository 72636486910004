@import 'styles/constants';
.root {
  height: 60px;
  width: 60px;
  border-radius: 9999px;
  overflow: hidden;
  color: #fff;

  background-color: $color-gray !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;

  //img{
  //  object-fit: cover;
  //  width: 100%;
  //  height: 100%;
  //}
}
