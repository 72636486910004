@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      margin: 0;
    }

    button {
      color: #4f4f4f;
      margin-left: 16px;
    }
  }
  @include media-breakpoint-down(sm) {
    .header {
      display: block;
      button {
        margin-left: 0px;
      }
    }
  }

  .stepWrapper {
    display: flex;
    flex-direction: column;
  }
}
