@import 'styles/constants';
.mapWrapper {
  border-radius: 10px;
  border: 1px solid $color-gray;
  margin-top: 18px;
  overflow: hidden;
  width: 100%;
  height: 250px;
}
.map {
  width: 100%;
  height: 100%;
}
