@import 'styles/constants';

.root {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 14px;
  //border-radius: 12px!important;

  button {
    flex: 1;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    height: 36px;
    border-radius: 10px;
    border: 1px #ececec solid;
    box-shadow: none;

    &:not(.isActive) {
      color: $color-gray;
      background-color: #fff;
    }
  }
}
