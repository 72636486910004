@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  .fields {
    margin-top: 20px;
  }
  .content {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.374px;
    color: $color-gray2;
  }

  .main {
    width: 50%;
  }
  button {
    color: $color-main;
    margin-bottom: -12px;
    margin-top: -12px;
  }

  .status {
    text-align: right;
    flex: 1;
    &.verif {
      color: $color-main-green;
    }
  }
}
.inputMobile {
  display: none !important ;
}

.inputDesctop {
  display: flex !important;
}
.header {
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.374px;
  margin-bottom: 20px;
  color: #000000;
}

@include media-breakpoint-down(sm) {
  .header {
    display: none;
  }
  .display {
    display: none;
  }
  .inputMobile {
    display: block !important;
  }
  .fields {
    border-top: none !important;
    margin-top: 20px;
  }

  .inputDesctop {
    display: none !important;
  }
}
