@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  .header {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 120%;
    color: $color-text;

    span {
      padding: 4px 21px;
    }

    span:first-child {
      font-weight: bold;
      border-right: 1px solid $color-gray;
      padding-left: 4px;
    }
  }
  @include media-breakpoint-down(xs) {
    .header {
      span {
        padding: 4px 10px;
      }
    }
  }

  .steps {
    display: flex;

    .step {
      flex: 1;

      background: $color-gray;
      border-radius: 5px;
      height: 8px;

      &.active {
        background: $color-main-green;
      }
    }

    .step:not(:last-child) {
      margin-right: 16px;
    }
  }
}
