//@import '~styles/constants';
//@import '~styles/mixins';

.IconContainer {
  //@include default-transition;

  display: inline-block;
  vertical-align: middle;

  > svg {
    display: block;
    //fill: currentColor;
  }
}
