@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.title {
  position: relative;
  font-weight: bold;
  font-size: 32px;
  left: 7.5px;
}
.campusContainer {
  position: relative;
  width: 100%;
  top: 25px;
  padding-bottom: 30px;
}
.header {
  display: flex;
}

@include media-breakpoint-up(md) {
  .buttonBack {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .header {
    align-items: center;
  }

  .title {
    position: relative;
    font-weight: bold;
    font-size: 24px;
    left: 7.5px;
  }
}
