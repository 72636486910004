@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$radius: 10px;
:root .root {
  padding: 17px;
  list-style-type: none;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 78px;
  display: flex;
  align-items: center;
  color: $color-gray2;
  cursor: pointer;
  //border: 1px solid transparent;

  @include media-breakpoint-up(md) {
    &.active {
      border: 1px solid $color-main;
      box-shadow: 0 0 10px rgba(156, 129, 240, 0.5);
      padding: 16px;

      .extraLabel {
        right: -1px;
        top: -1px;
      }
    }
  }

  .ico {
  }
  .text {
    flex: 1;
    margin: 0 16px;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #1f2126;
    }
    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $color-gray2;
      margin-bottom: 8px;
    }
  }

  .extraLabel {
    position: absolute;
    right: 0;
    top: 0;
    height: 16px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    background: $color-gray;
    box-shadow: 0 0 10px rgba(200, 199, 204, 0.4);

    font-weight: bold;
    font-size: 8px;
    line-height: 9px;
    text-transform: uppercase;
    color: #ffffff;
    border-top-right-radius: $radius;
    border-bottom-left-radius: $radius;

    &.extraLabelActive {
      background: $color-main-green;
      box-shadow: 0 0 10px rgba(58, 149, 69, 0.4);
    }
  }
  .extraLableMain {
    background: $color-stop;
  }
}
