@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  margin: 0 auto;
  max-width: 730px;
  padding-bottom: 30px;
  font-size: 1.06em;
  font-family: 'Times New Roman', serif;

  h1 {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1.2em;
    font-size: 1em;
    text-decoration: underline;
  }

  .title {
    font-weight: bold;
    text-align: center;
    margin: 18px 0 4px;
  }

  p {
    text-indent: 2.8em;
    margin: 0;
    text-align: justify;
  }

  .footer {
    text-align: center;
    margin-top: 18px;
  }

  ul {
    //text-indent: 2.8em;
    text-align: justify;
    //list-style-type: disc;
    list-style: disc inside none;
    margin: 0;
    padding: 0;
  }

  li {
    text-indent: 2.8em;
  }
}
@include media-breakpoint-down(sm) {
  .root {
    padding-left: 15px;
    padding-right: 15px;
  }
}
