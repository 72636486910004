@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  .header {
    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      margin: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      button {
        color: #4f4f4f;
        margin-left: 16px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .header {
      display: block;
      margin-bottom: 32px;
      button {
        color: #4f4f4f;
      }
    }
  }

  .stepWrapper {
    display: flex;
    flex-direction: column;
  }
}
