@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  text-align: center;
  .paper {
    height: 442px;
    min-height: 442px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;

    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .content {
    width: 350px;
    color: $color-text;
    text-align: center;
    position: relative;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      margin-bottom: 48px;
    }

    .ico {
      color: $color-stop;
      svg {
        height: 71px;
        width: 71px;
      }
    }

    .text {
      margin: 48px 0 32px;
    }

    button {
      width: 100%;
      background-color: $color-gray2;
      box-shadow: 0 0 10px rgba(142, 142, 147, 0.5);
    }

    &.success {
      .ico {
        color: $color-main-green;
      }
      button {
        background-color: $color-main-green;
        box-shadow: 0 0 10px $color-main-green;
      }
    }

    &.default {
      button {
        background-color: $color-main;
        box-shadow: 0 0 10px rgba(156, 129, 240, 0.5);
      }
    }
    @include media-breakpoint-down(sm) {
      button {
        width: 218px;
        height: 46px;
        margin: 0 auto;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .content {
      width: 290px;
    }
  }
}
