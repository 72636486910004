@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  .fields {
    margin-top: 20px;
  }
}

.header {
  padding-bottom: 20px;
  border-bottom: 1px solid $color-gray;
  min-height: 53px;
}

.inputMobile {
  display: none !important;
}

.inputDesctop {
  display: flex !important;
}

@include media-breakpoint-down(sm) {
  .header {
    display: none;
  }

  .inputMobile {
    display: block !important;
  }

  .inputDesctop {
    display: none !important;
  }
}
