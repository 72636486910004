@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.itemWrapper {
  width: 100%;
  padding: 8px 15px;

  .checkedItem {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 120%;
    color: $color-text;
    min-height: 56px;
    text-align: left;

    .check {
      color: $color-gray;
      margin-right: 16px;
      height: 20px;
      width: 20px;

      &.img {
        height: 24px;
        width: 24px;
      }

      svg,
      img {
        height: 100%;
        width: 100%;
        position: relative;
        left: 22px;
      }
    }
    span {
      flex: 1;
    }
  }
  .checkItemNeeded {
    border: 1px solid #eb5757;
  }
}

.itemFilledWrapper {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 8px 15px;
}
@include media-breakpoint-down(sm) {
  .itemWrapper {
    padding: 8px 0;
  }
  .itemFilledWrapper {
    padding: 8px 0;
  }
}
.itemFilled {
  padding: 0 17px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 78px;
  border: 1px solid $color-main-green;
}

.itemName {
  height: 56px;
  display: flex;
  align-items: center;
}
.loadFile {
  position: relative;
  left: 28px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: $color-gray2;
}

.line {
  height: 1px;
  width: 100%;
  background-color: #c8c7cc;
}
.fileName {
  position: relative;
  left: 5px;
  width: 72%;
  white-space: nowrap;
  overflow: hidden;
}
.checkCircle {
  color: $color-main-green;
  width: 50px;
}
.deleteFile {
  position: relative;
  right: 10px;
}
.formatImg {
  position: relative;
  right: 12px;
}
