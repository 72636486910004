@import 'styles/constants';
.root {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: $color-text;

  .txt {
    margin: 16px 0;
  }

  .version {
    color: $color-gray2;
    margin: 16px 0 32px;
  }

  .link {
    font-size: 16px;
    display: flex;
  }
}
