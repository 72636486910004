@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$avatar-size-xl: 64px;
$avatar-size-md: 40px;
$avatar-size-sm: 32px;
.root {
  padding: 20px 16px;
  position: relative;
  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: $color-gray2;
    margin-bottom: 19px;
  }

  .code {
    position: absolute;
    background: $color-main;
    box-shadow: 0 0 10px rgba(156, 129, 240, 0.4);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    padding: 2px 10px;
    min-width: 130px;
    text-align: center;
    top: 0;
    right: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .teacher {
    .avatar {
      width: $avatar-size-xl;
      height: $avatar-size-xl;

      i {
        width: 100%;
        height: 100%;
      }
    }
    @include media-breakpoint-up(md) {
      .text {
        flex: 1;
        font-size: 16px;
        line-height: 120%;
        color: #8a8a8f;
        margin: 0 20px;

        .fio {
          font-weight: 500;
          font-size: 20px;
          line-height: 120%;
          color: #000;
          margin-bottom: 8px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      .text {
        flex: 1;
        font-size: 16px;
        line-height: 20px;
        color: #8a8a8f;
        margin: 0 20px;
        padding-left: 10px;

        .fio {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #000;
          margin-bottom: 8px;
        }
      }
      .avatar {
        margin-bottom: 20px;
        width: $avatar-size-md;
        height: $avatar-size-md;

        i {
          width: 100%;
          height: 100%;
        }
      }
    }

    .phoneIco {
      color: $color-main;
    }
  }

  .teacher:not(:last-child) {
    border-bottom: 1px solid $color-gray;
  }
}
@include media-breakpoint-up(md) {
  .teacher {
    display: flex;
    padding: 0 16px;
    align-items: center;
    min-height: 88px;
  }
}

@include media-breakpoint-down(md) {
  .teacher {
    display: flex;
    align-items: center;
    min-height: 88px;
  }
}

@media (max-width: 320px) {
  .fio {
    font-size: 14px !important;
    word-break: break-word;
    width: 90%;
  }
  .phone {
    font-size: 14px;
    width: 105%;
  }
}
