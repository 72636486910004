@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.content {
  display: flex;
  flex-direction: column;
  border: 1px red solid;
}
.wrapper {
  //border: 1px solid;
  //flex: 1;
  //margin-bottom: 32px;
  //overflow-y: hidden;
  //height: calc(100vh - 336px);
}
.page {
  height: 100%;
  padding: 50px 50px 0;
  overflow-y: hidden;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    min-height: 500px;
    overflow-y: visible;
  }
}
@include media-breakpoint-down(sm) {
  .page {
    padding: 20px 0;
  }
}
