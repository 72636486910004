@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$panelMargin: 20px;
$photoSize: 80px;
$photoMargin: 8px;
.root {
  flex: 1;
  padding-bottom: 30px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    .iconBack {
      display: none;
    }
    .desctopBtn {
      min-width: 215px;
    }

    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      .iconBack {
        display: inline;
        padding: 0px;
        padding-right: 5px;
      }
      h1 {
        font-size: 24px;
        line-height: 24px;
      }
      .desctopBtn {
        display: none;
      }
      .title {
        display: flex;
        align-items: center;
      }
    }
  }
  .agree {
    width: 552px;
    font-size: 20px;
    padding-bottom: 20px;
  }
  @include media-breakpoint-down(sm) {
    .agree {
      width: 90%;
      font-size: 16px;
    }
  }

  .descr {
    display: flex;
    margin: $panelMargin 0;

    > *:not(:last-child) {
      margin-right: 16px;
    }

    > *:not(.cost) {
      flex: 1;
    }
  }
  .mobileBtn {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    .mobileBtn {
      margin-top: 20px;
      width: 100%;
      display: block;
    }
  }
}
