@import 'styles/constants';
@import 'styles/mixins/breakpoints';
.root {
  .header {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    margin-bottom: 32px;
    @include media-breakpoint-down(sm) {
      .title {
        display: none;
      }
    }
    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      margin: 0;
    }

    button {
      //min-width: 215px;
      color: #4f4f4f;
      margin-left: 16px;
    }
  }

  .stepWrapper {
    display: flex;
    flex-direction: column;
    //padding-bottom: 30px;
    //
    //  .step {
    //    flex: 1;
    //  }
  }

  //.footer {
  //  display: flex;
  //  justify-content: flex-end;
  //  align-items: center;
  //
  //  .text {
  //    font-size: 14px;
  //    line-height: 120%;
  //    text-align: right;
  //    color: $color-gray2;
  //    max-width: 353px;
  //    span {
  //      color: $color-main;
  //    }
  //  }
  //  .btnNext {
  //    min-width: 215px;
  //    margin-left: 32px;
  //  }
  //}
}
