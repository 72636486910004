@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  //border: 1px solid red;
  width: 350px;
  margin-right: 30px;
  padding: $btn-padding 16px 0;
  overflow-y: hidden;
  margin-top: -$btn-padding;
  position: relative;

  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: $color-text;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin: -$btn-padding;

      &.activeFilter {
        color: $color-main-green;
      }
    }
  }
  .headerWrapper {
    box-sizing: content-box;
  }

  .simpleBar {
    margin: 0 -10px;
    padding: 0 10px;

    :global(.simplebar-content) {
      padding: 0 10px !important;
    }
    :global(.simplebar-wrapper) {
      margin: 0 -10px !important;
    }
  }

  .bottomLabel {
    position: absolute;
    padding-bottom: 16px;
    text-align: center;
    font-size: 14px;
    bottom: 0;
    left: 0;
    right: 0;

    color: $color-gray;
  }
}

@include media-breakpoint-down(md) {
  .root {
    width: 290px;
    .bntExitForMobile {
      display: inline;
    }
  }
}

@include media-breakpoint-down(sm) {
  .root {
    //width: 320px;
    width: 100%;
    margin-right: 0;

    &:not(.rootPath) {
      display: none;
    }
  }
}
