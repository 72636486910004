$color-main: #69488d;
$color-main-green: #3a9545;
$color-stop: #eb5757;
$color-text: #1f1f1f;
$color-gray: #c8c7cc;
$color-gray2: #8e8e93;
$color-background: #f3f1f5;

$layout-content-width: 1126px;
$layout-header-height: 100px;

$btn-padding: 12px;
