@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  margin: 32px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    font-size: 24px;
    line-height: 120%;
    color: $color-text;
    margin-bottom: 24px;
  }

  .content {
    flex: 1;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 18px;

    .fileLoader {
      color: $color-gray2;
      font-size: 16px;
      float: left;
      width: 60%;
    }

    .btnBackMobile {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      .text {
        font-size: 14px;
        line-height: 120%;
        text-align: right;
        color: $color-gray2;
        max-width: 353px;

        span {
          color: $color-main;
          cursor: pointer;
        }
      }
      .fileLoaderForMobile {
        display: none;
      }
    }

    .textForMobile {
      display: none;
    }

    .btnNext {
      min-width: 215px;
      margin-left: 32px;
    }
  }

  @include media-breakpoint-down(sm) {
    .content {
      flex: 0;
    }
    .footer {
      display: block;

      .btnBack {
        display: none;
      }
      .fileLoader {
        display: none;
      }
      .btnNext {
        margin: 0 auto;
        width: 100%;
      }

      .btnBackMobile {
        display: block;
        margin: 0 auto;
      }
      .text {
        display: none;
      }

      .textForMobile {
        display: block;
        font-size: 14px;
        line-height: 120%;
        margin-top: 10px;
        color: $color-gray2;
        max-width: 353px;

        span {
          color: $color-main;
          cursor: pointer;
        }
      }
      .fileLoaderForMobile {
        display: block;
        color: $color-gray2;
        font-size: 16px;
      }
    }
  }

  .saver {
    margin-top: 18px;
    font-size: 14px;
    line-height: 120%;
    color: $color-gray2;
    max-width: 350px;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $color-text;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }

    i {
      color: $color-gray;
      margin-right: 8px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &.needSave {
      i {
        color: $color-main-green;
      }
    }
  }
}
