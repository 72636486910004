@import 'styles/constants';

.root {
  flex-wrap: wrap;
}
.notActive {
  opacity: 0.5;
}

.address {
  flex: 1;
  font-size: 16px;
  line-height: 19px;
  color: $color-gray2;
}

.title {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
}

.name {
  flex: 1;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $color-main;
}
