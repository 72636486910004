@import 'styles/mixins/breakpoints';

.root {
  .deleteBtn {
    float: right;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}
.titleContainer {
  display: none;

  align-items: center;
  margin-bottom: 20px;

  button {
    margin: -12px;
    margin-right: 0;
  }

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  .deleteBtn {
    float: right;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

@include media-breakpoint-down(sm) {
  .titleContainer {
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
    }
  }
}
