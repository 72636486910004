@import 'styles/constants';

.filter {
  position: relative;
  margin: 16px 0;
  line-height: 120%;
  display: flex;
  align-items: center;

  .select {
    outline: none;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    border-radius: 10px;
    width: 100%;
    min-height: 36px;
    padding: 0 8px 0 14px;
    margin: 0;
    color: $color-gray2;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 48px;
    cursor: pointer;
  }
  .activeSelect {
    border: 1px solid $color-gray2;
    border-bottom: none;
  }
  .classesContainer {
    position: absolute;
    width: 100%;
    left: 0;
    top: 40px;
    height: 220px;
    border: 1px solid $color-gray2;
    border-top: none;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0, 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .classes {
    width: 100%;
    height: 100%;
  }
  .class {
    cursor: pointer;
    padding: 0 8px 0 14px;
    margin: 0 auto;
    color: black;
    height: 40px;
    width: 100%;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
  }
  .class:hover {
    background-color: #f2f2f2;
  }

  .icon {
    position: absolute;
    z-index: 2;
    right: 10px;
  }
  .invertedIcon {
    transform: rotate(180deg);
  }
}
.default {
  color: $color-gray2;
  max-width: 540px;
  display: flex;
  align-items: center;
  max-height: 700px;
  margin: 0 auto;
  height: 100%;
  font-size: 20px;
  line-height: 24px;
}
.menu {
  list-style-type: none;
  padding: 10px 0 30px;
  margin: 0;

  li:not(:last-child) {
    margin-bottom: 16px;
  }
}
