.root {
  position: relative;
  //margin: 16px 0;
  font-size: 24px;
  line-height: 120%;

  input {
    background: rgba(118, 118, 128, 0.12);
    border-radius: 10px;
    font-size: 17px;
    line-height: 22px;
    border: none;
    width: 100%;
    height: 36px;
    padding: 0 8px 0 36px;
    margin: 0;
  }
  i {
    position: absolute;
    left: 9px;
    top: 9px;
  }
}
