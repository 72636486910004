.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #8e8e93;
  font-size: 24px;
  text-align: center;
}
