@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$panelMargin: 20px;
$photoSize: 80px;
$photoMargin: 8px;
.root {
  flex: 1;
  padding-bottom: 30px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    .title {
      min-width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      margin: 0;
    }
    @include media-breakpoint-down(sm) {
      .title {
        min-width: 350px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .iconBack {
          padding: 0px;
          padding-right: 5px;
        }
      }
      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        margin: 0;
      }
    }

    .buttonForDesctop {
      width: 215px;
    }

    @include media-breakpoint-down(sm) {
      .buttonForDesctop {
        display: none;
      }
    }
  }

  .descr {
    display: flex;
    margin: $panelMargin 0;
    gap:16px;
    div {
      min-width: fit-content;
      width:100%;
    }
  }
  @include media-breakpoint-down(sm) {
    .title {
      word-wrap: break-word;
      min-width: 94vw !important;
    }
    .descr {
      margin: 20px 0;
      display: block;

      > *:not(:last-child) {
        margin-bottom: 20px;
      }
      .cost {
        min-width: 100%;
        margin-bottom: 20px;
      }

      > *:not(.cost) {
        min-width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .buttonForMobile {
      display: none !important ;
    }
    .iconBack {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .buttonForMobile {
      width: 100%;
      margin-top: 15px !important;
      position: relative;
      transform: translate(-50%, 0px);
      left: 50%;
    }
  }
}
