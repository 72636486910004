.root {
}

.filter {
  margin: 16px 0;
}

.menu {
  list-style-type: none;
  padding: 10px 0 30px;
  margin: 0;
  //border: 1px solid;
  li:not(:last-child) {
    margin-bottom: 16px;
  }
  .emptyFilter {
    text-align: center;
  }
}
