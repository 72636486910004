@import 'styles/mixins/breakpoints';
@import 'styles/constants';

.paper {
  width: unset !important;
  padding: 0 20px 20px;
}
.btnOk {
  width: 215px;
}
@include media-breakpoint-down(xs) {
  .paper {
    padding: 0 0 20px;
  }
}
