@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  flex-wrap: wrap;
  .imgContainer {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include media-breakpoint-down(sm) {
    .imgContainer {
      width: 50px;
      height: 50px;
    }
  }
  .active {
    border: 3px solid green;
  }
}
