@import 'styles/mixins/breakpoints';

.root {
  margin: 0 auto;
  max-width: 750px;
  min-height: 100vh;
  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gifStyle {
    padding-top: 20px;
  }

  img {
    height: 245px;
  }

  .politic {
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
  }
  .politicGray {
    font-size: 16px;
    margin-top: 24px;
    color: #8e8e93;
    font-weight: 500;
  }
}

.extraText {
  width: 100%;
  height: 442px + 46px + 60px;
  position: relative;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow-y: hidden;
  padding: 10px 0;

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .extraTextContent {
    padding: 20px 64px;
    height: 100%;
    overflow-y: auto;
    scrollbar-color: #f5f5f5;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b0b4b8;
      border-radius: 3px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .root {
    img {
      height: 144px;
    }
    .gifStyle {
      padding-top: 0px;
    }
    .noneImg {
      display: none;
    }
    .politic {
      font-weight: bold;
      font-size: 16px;
    }
    .politicGray {
      font-weight: bold;
      font-size: 16px;
    }
    .extraTextContent {
      padding: 0px;
    }
  }
}

@include media-breakpoint-between(xs, sm) {
  .root {
    img {
      height: 247px;
    }
    .noneImg {
      display: block;
    }
    .gifStyle {
      padding-top: 0px;
    }
    .politic {
      font-size: 14px;
      margin-top: 0px;
    }
    .politicGray {
      font-size: 14px;
      margin-top: 0px;
    }
    .extraTextContent {
      padding: 0px;
    }
  }
}
