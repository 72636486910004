$panelMargin: 20px;
$photoSize: 80px;
$photoMargin: 8px;
.photosPanel {
  margin-bottom: $panelMargin;

  .photos {
    display: flex;
    flex-wrap: wrap;
    margin: -$photoMargin;

    .photo {
      width: $photoSize;
      height: $photoSize;
      border-radius: 8px;
      overflow: hidden;
      margin: $photoMargin;
      cursor: pointer;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
