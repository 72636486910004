@import 'styles/constants';

.root {
    background-color: $color-background;
    width: 100%;
    height: calc(100vh - 132px);
    overflow-y: auto;
    scrollbar-color: #f5f5f5;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b0b4b8;
      border-radius: 3px;
    }
}