@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$avatar-sizeLG: 72px;

$avatar-sizeSM: 60px;
:root .root {
  margin-bottom: 33px;
}
.avatarWrapper {
  display: flex;
  align-items: center;

  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: $color-text;
  }

  .avatar {
    width: $avatar-sizeLG;
    height: $avatar-sizeLG;
    background-color: $color-gray;
    color: #fff;
    margin-right: 16px;
    border-radius: 999px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.withImg {
      background-color: transparent;
    }

    //&:hover {
    //  background-color: $color-gray;
    //}

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      text-align: center;
      color: transparent;
      text-indent: 10000px;
    }
  }
  @include media-breakpoint-down(sm) {
    .avatar {
      height: $avatar-sizeSM;
      width: $avatar-sizeSM;
    }
  }

  .noPhoto {
    width: 60%;
    height: 60%;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
