@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.itemWrapper {
  width: 50%;
  display: inline-block;
  padding: 8px 15px;
  position: relative;
  right: 17px;
  .checkedItem {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 120%;
    color: $color-text;
    min-height: 56px;

    .check {
      color: $color-gray;
      margin-right: 16px;
      height: 20px;
      width: 20px;

      &.img {
        height: 24px;
        width: 24px;
      }

      svg,
      img {
        height: 100%;
        width: 100%;
        position: relative;
      }
      img {
        object-fit: cover;
      }
    }
    span {
      flex: 1;
    }

    &.selected {
      .check {
        color: $color-main-green;
      }
    }
  }
  .checkItemNeeded {
    border: 1px solid #eb5757;
  }
}
@include media-breakpoint-down(sm) {
  .itemWrapper {
    width: 100%;
    position: relative;
    right: 0px;
  }
}

.subtitle {
  font-size: 18px;
  line-height: 120%;
  color: $color-text;
  margin: 12px 0 12px;
}
.subtitleNoNeedable {
  font-size: 18px;
  line-height: 120%;
  color: $color-text;
  margin-top: 24px;
  margin-bottom: 12px;
}
.files {
  position: relative;
  right: 17px;
  width: 100%;
  .file {
    width: 50%;
    display: inline-block;
  }
  img {
    margin-left: -25px;
  }
}
@include media-breakpoint-down(sm) {
  .itemWrapper {
    padding: 8px 0;
  }
  .files {
    position: relative;
    right: 0px;
    .file {
      width: 100%;
      display: block;
    }
  }
}
