@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  padding: 36px 50px;

  .editFooter {
    margin-top: 32px;
    text-align: right;

    .cancelBtn {
      color: $color-gray2;
      //min-width: 138px;
    }

    .saveBtn {
      min-width: 215px;
      color: #f6f7f8;
      margin-left: 40px;
    }

    .footerText {
      margin: 32px 0;
      font-size: 16px;
      line-height: 120%;
      color: #4f4f4f;

      span {
        max-width: 376px;
        display: inline-flex;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .root {
    padding: 24px 15px;

    .editFooter {
      margin-top: 0px;

      .cancelBtn {
        display: block;
        margin: 0 auto;
      }

      .saveBtn {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}
