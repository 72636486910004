.container {
  margin: 20px 0;
  .block {
    color: #69488d;
    font-weight: 500;
    display: block;
    line-height: 20px;
    &:not(first-child) {
      margin-top: 16px;
    }
  }
}
.filter {
  margin-top: 20px;
}
.notFound {
  text-align: center;
}
.disabled {
  opacity: 0.5;
}
