@import 'styles/constants';
@import 'styles/mixins/breakpoints';

$contentWidthMd: 350px;
$contentWidthSm: 270px;

.root {
  .paper {
    //padding: 40px 190px;
    position: relative;
    height: 442px;
    min-height: 442px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .paperAgreement {
    display: block;
    padding: 20px;
    scrollbar-color: #f5f5f5;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f5f5f5;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b0b4b8;
      border-radius: 3px;
    }
  }

  .content {
    margin: 0 auto;
    width: $contentWidthMd;
  }

  .title {
    font-weight: 900;
    font-size: 28px;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 25px;
    text-align: center;
  }

  .text {
    height: 70%;
    label {
      margin-bottom: 20px;
    }
  }
  .btnContinue {
    width: $contentWidthMd;
    height: 64px;
  }

  @include media-breakpoint-down(sm) {
    .content {
      margin: 0 auto;
      width: $contentWidthSm;
    }

    .btnContinue {
      width: $contentWidthSm;
      height: 46px;
    }
  }

  .link {
    color: $color-main !important;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: $color-main;
  }
}
