@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.header {
  min-height: 45px;
}
.inputMobile {
  display: none !important;
}
.inputDesctop {
  display: flex !important;
}
.fields {
  border-top: 1px solid $color-gray;
}

@include media-breakpoint-down(sm) {
  .header {
    display: none;
  }

  .inputMobile {
    display: block !important;
  }
  .inputDesctop {
    display: none !important;
  }
}
