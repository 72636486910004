.container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
    .title {
      line-height: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    .buttonForDesctop {
      width: 215px;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .content {
    margin-top: 24px;
    width: 100%;
    height: 236px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 26px;
    justify-content: center;
    @media (max-width: 768px) {
      display: block;
      padding: 16px;
      height: auto;
      padding-bottom: 44px;
    }
    .title {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #8e8e93;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 768px) {
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
      }
    }
    .description {
      font-size: 24px;
      color: #8e8e93;
      line-height: 1.4;
      text-align: center;
      @media (max-width: 768px) {
        margin-top: 44px;
        font-size: 20px;
      }
    }
  }
  .buttonForMobile {
    margin-top: 50px;
    width: 100%;
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
}
