@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.root {
  .title {
    font-size: 24px;
    line-height: 120%;
    color: $color-text;
    margin: 32px 0;
    display: flex;
    align-items: center;

    button {
      margin: -12px;
    }

    > span {
      margin-left: 19px;
    }
  }

  .panel {
    padding: 50px;

    .footer {
      display: flex;
      margin-top: 100px;
      font-size: 16px;
      line-height: 120%;
      color: $color-gray2;

      button {
        min-width: 215px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .panel {
      padding: 50px 10px 50px 10px;

      .footer {
        display: block;
        margin-top: 50px;
        button {
          margin-top: 10px;
          min-width: 100%;
        }
        .announce {
          float: right;
          text-align: right;
          width: 70%;
        }
      }
    }
  }
}
