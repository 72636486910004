.root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 30px;
  .content {
    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 32px;
        font-weight: bold;
      }
      .btnClose {
        color: #8e8e93;
      }
    }
    .subTitle {
      margin-top: 24px;
      font-size: 24px;
    }
    .wrapper {
      margin-top: 24px;
      .page {
        height: 100%;
        padding: 50px 50px 0;
        overflow-y: hidden;

        iframe {
          border: none;
          width: 100%;
          height: 100%;
          min-height: 435px;
          overflow-y: visible;
        }
      }
    }
  }
  .footer {
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
    .btnClose {
      color: #8e8e93;
    }
    .btnNext {
      margin-left: 24px;
      width: 160px;
      height: 46px;
      font-size: 15px;
      font-weight: 500;
    }
    .btnDecline {
      margin-left: 48px;
      border: 1px solid #eb5757;
      font-size: 15px;
      width: 160px;
      height: 46px;
      border-radius: 41px;
      color: #eb5757;
    }
  }
}
