@import 'styles/mixins/breakpoints';
.root {
  width: 100%;
  //height: 442px;
  min-height: 442px;
  //height: 100%;
  position: relative;
  //max-height: 422px;
  //min-height: 380px;
  //text-align: center;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //padding: 0 190px;
  display: flex;
  align-items: center;

  .wrapper {
    margin: 0 auto;
    max-width: 350px;
    text-align: center;

    > * {
      width: 100%;
    }
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
  }

  &.showFrame {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.root {
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 0 10px;
    }
    .closeBtn {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
