@import 'styles/constants';
@import 'styles/mixins/breakpoints';

.campusContainer {
  height: 255px;
  width: 355px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.campus {
  cursor: pointer;
  height: 240px;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(100%);
  filter: gray;

  &:hover {
    border: 1px solid $color-gray2;
    filter: none;
  }
}

.campusClicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.campusContentContainer {
  height: 215px;
  width: 315px;

  .imgContainer {
    height: 70%;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
@include media-breakpoint-down(md) {
  .campusContainer {
    width: 295px;
  }

  .campus {
    width: 282px;
  }

  .campusContentContainer {
    height: 215px;
    width: 270px;
  }
}

@include media-breakpoint-down(sm) {
  .campusContainer {
    height: 255px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .campus {
    cursor: pointer;
    height: 240px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(100%);
    filter: gray;

    &:hover {
      border: 1px solid $color-gray2;
      filter: none;
    }
  }

  .campusContentContainer {
    height: 215px;
    width: 90%;
  }
}

.campusClicker {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  height: 15%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.address {
  height: 15%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $color-gray2;
}
