@import 'styles/constants';

$radius: 10px;
.root {
  padding: 17px;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 78px;
  display: inline-flex;
  align-items: center;
  color: $color-gray2;
}
